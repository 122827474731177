/***************************************************************/
//             STYLE CIBLÉ DE L'APP - propre au projet courant
// NE PAS TOUCHER TANT QUE TU N'AURAS PAS LU LA CONVENTION GRAPHIQUE!
//     ici : PROG_WEB\Documentation - Formations\Documentations diverses\Conventions et bonnes pratiques\Conventions graphiques - ApplicationWEB.pptx
/***************************************************************/

@import "variables.scss";

/* NUMERIC KEYBOARD */

.kbd-bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0009;
  z-index: 9;
  margin: 0;
  padding: 0;
}

.kbd-hide {
  display: none;
}

.kbd-container {
  background-color: $app-background-color;
  border: solid 1px $app-border-color;
  text-align: center;
  margin-top: 3em;
  padding: 10px;
  border-radius: 10px;
  z-index: 9;
}

.kbd-titre {
  font-size: 16px;
  font-weight: 500;
}

.kbd-input {
  background-color: $app-background-color;
  width: 190px;
  line-height: 35px;
  border-radius: 5px;
  border: solid 1px $app-border-color;
  margin: 10px 0;
  letter-spacing: 2px;
  text-align: center;
  font-size: 20px;
  color: #fff;
}

.kbd-alert-txt {
  color: red;
  margin-bottom: 10px;
}

.kbd-container .kbd-btn-long {
  width: 98px;
  height: 45px;
  margin: 2px;
  margin-bottom: 10px;
  border-color: $app-border-color;
  color: $app-border-color;
  font-size: 20px;
}

.kbd-container .kbd-btn {
  height: 60px;
  margin: 2px;
  border-color: $app-border-color;
  font-size: 20px;
}

.kbd-container .kbd-btn:hover {
  background-color: $sidenav-item-hover;
}

.kbd-container .kbd-btn-footer {
  width: 98px;
  height: 45px;
  margin: 2px;
  border-color: $app-border-color;
  margin-top: 10px;
  font-size: 14px;
}

/* INPUT */

.input-label {
  margin-right: 5px;
}

.input-stroked {
  height: 32px;
  line-height: 30px;
  margin-right: 5px;
  background-color: $input-background-color;
  border: solid 1px $input-border-color;
  border-radius: 5px;
  color: $input-text-color;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

/* AJOUTS LAURIE */

.bg-gris {
  background-color: #1e293b;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
}

.scan2 .info-txt-container {
  width: 80%;
}

/*.scan2 .btn-probleme{
  width:20%;
  text-align:right;
}*/

.scan2 p {
  display: inline-block;
  margin: 0;
}

.vert {
  color: #179143;
  font-weight: 700;
}

.rouge {
  color: red;
  font-weight: 700;
}

.orange {
  color: orange;
  font-weight: 700;
}

/* classe pour le display flex avec alignement au centre */

.flex-center {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 20px 30px;
}

/* FIX STYLE APRES UPDATE  MATERIAL */

.mat-mdc-row,
.mat-mdc-dialog-container,
.mat-mdc-menu-panel {
  background-color: $app-background-color !important;
}

.mat-mdc-header-row {
  background-color: $table-header-row !important;
  color: $table-header-text-color !important;
  font-size: 12px !important;
}

.mat-mdc-menu-panel {
  border: solid 1px #374252;
}

.mat-no-data-row,
.mat-mdc-no-data-row {
  background-color: $app-background-color !important;
  line-height: 50px;
  color: #fff;
  padding-left: 30px;

  .mat-cell,
  .mat-mdc-cell {
    padding-left: 20px;
  }
}

.k-table-thead {
  background-color: $table-header-row !important;
  color: $table-header-text-color !important;
  font-size: 12px !important;
}

.k-table-td {
  color: #fff;
}

a.mdc-button {
  text-decoration: none;
}

.k-grid-pager {
  background-color: $table-header-row;
  color: #fff;
}

.mat-mdc-dialog-surface {
  background-color: $app-background-color !important;
  color: #fff;
  padding: 10px;
  border: solid 1px #374252;
}

.mat-mdc-dialog-content {
  color: #fff !important;
}
