/***************************************************************/
//             STYLE GLOBAL DE L'APP
// NE PAS TOUCHER TANT QUE TU N'AURAS PAS LU LA CONVENTION GRAPHIQUE!
//     ici : PROG_WEB\Documentation - Formations\Documentations diverses\Conventions et bonnes pratiques\Conventions graphiques - ApplicationWEB.pptx
/***************************************************************/

/* MATERIAL BASE STYLE */

@use "@angular/material" as mat;
@include mat.core();

/* VARIABLES */

@import "variables.scss";

$app-primary: mat.m2-define-palette($custom-primary-palette);
$app-accent: mat.m2-define-palette(mat.$m2-amber-palette);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$app-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@include mat.all-component-themes($app-theme);

/* OVERRIDE DEFAULT MATERIAL */
.mat-drawer-content {
  overflow: hidden !important;
}

.mat-drawer-container,
.mat-drawer,
.mat-table,
.mat-toolbar,
.mat-dialog-container,
.mat-select-panel,
.mat-datepicker-content,
.mat-form-field-appearance-fill .mat-form-field-flex,
.mat-form-field-appearance-fill .mat-form-field-infix,
.mat-expansion-panel-header,
.mat-menu-panel,
.mat-mdc-select-panel {
  background-color: $app-background-color !important;
}

.mat-select-panel,
.mat-datepicker-content,
.mat-menu-panel,
.mat-dialog-container,
.mat-mdc-select-panel,
.mat-mdc-menu-panel {
  border: solid 1px $app-border-color;
}

.mat-menu-panel {
  min-height: 53px !important;
}

.mat-expansion-panel {
  background-color: transparent !important;

  .mat-mdc-button {
    line-height: 44px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #c9cbcf !important;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }
}

.mat-header-cell {
  background-color: $table-header-row;
}

.mat-tooltip {
  white-space: pre-line; // Permet les sauts de lignes dans les tooltips
  font-size: 15px;
}

// GENERAL

* {
  font-family: "Inter", sans-serif;
}

h1 {
  padding: 0px;
  margin: 0px;
}
h2 {
  padding: 0px;
  margin: 0px;
}
h3 {
  padding: 0px;
  margin: 0px;
}

//************************************************
/* Classe global au niveau du SITE              */
// rappel : Voir en haut du document ici avant de changer des choses...
//************************************************
.app-master-container {
  height: 100%;
  margin: 0;
  font-size: 15px;
  overflow: hidden;
}

.site-navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.site-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  height: $site-toolbar-height;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px $app-border-color;
}
.site-main-container {
  height: calc(
    #{"100% - #{$site-toolbar-height} - #{$site-footer-height}"}
  ); /*total - siteToolbar - SiteFooter*/ // https://stackoverflow.com/questions/16660985/how-can-i-pass-an-expression-to-a-calc-mixin-that-uses-sass-variables
  display: block;
  background: #040a19;
  padding-top: 10px;
  padding-left: 10px;
}
.site-footer {
  position: fixed;
  bottom: 0;
  height: $site-footer-height;
  z-index: 1;
  width: 100%;
}

//************************************************
//Classe pour le main content (main-page) - global
// rappel : Voir en haut du document ici avant de changer des choses...
//************************************************

.main-page-toolbar {
  display: block;
  height: $mainpage-toolbar-height;
  background-color: $app-PageTitle-BackColor;
  color: $app-PageTitle-FontColor;
}

/*Repésente*/
.main-page-container {
  display: flex;
  flex-direction: row;
  height: calc(#{"100% - #{$mainpage-toolbar-height}"}); /*hauteur totale MOINS hauteur du mainpage toolbar moins*/
  overflow: hidden;
}

//************************************************
//Classe pour une page de LISTE (tableau)
//************************************************

.listpage-container {
  flex: 60;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1300px) {
  .listpage-container {
    flex: 50;
  }
}
// Forcer les kendo grid à prendre toute la hauteur
.listpage-container kendo-grid {
  height: 100%;
  // empêche que le contenu fait déborder le pager du grid en dehors du viewport
  // la valeur à peu d'importance tant qu'elle est plus petite que la hauteur du grid
  .k-grid-table-wrap {
    max-height: 100px;
  }
}

.listpage-header {
  position: sticky;
  top: 0px;

  width: 100%;
}

.listpage-header-block {
  display: block;
}
.listpage-body {
  overflow: auto;
  height: 100%;
  width: 100%;
}

.listpage-search-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1em 0px 0 0.5em;
  margin: 0;

  > * {
    margin-right: 5px;
  }
}

.listpage-footer {
  position: sticky;
  bottom: 5px;
  z-index: 1;
  background-color: $app-border-color;
}

//************************************************
/*Classe pour le FORM de droite*/
//************************************************

.formpage-container {
  flex: 40;
  width: 100%;
  height: 100%;
  border-left: solid $app-border-color 1px;

  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1300px) {
  .formpage-container {
    flex: 50;
  }
}

.formpage-header {
  position: sticky;
  top: 0px;
  width: 100%;
  padding-left: 20px;

  border-bottom: solid $app-border-color 1px;
}

.formpage-body {
  padding-left: 20px;
  overflow: auto;
  height: 100%;
}

.formpage-footer {
  position: sticky;

  bottom: 0;
  padding: 15px 15px 15px 15px;
  background-color: #374262;

  display: block;
}

/* CUSTOM CLASSES */

// PAGE

.page {
  margin: 10px;
  height: 100%;
}

.modal-preventChange {
  z-index: 1000;
  position: absolute; //le parent doit être relative pour que ca marche
  width: 100%;
  height: 100%;
  background-color: #12111f;
  opacity: 0.7;

  text-align: center; //pour mettre le texte au centre (horizontal)
  padding-top: 50%; //pour mettre le texte au centre (vertical)
}

// PAGE TITRE

.page-titre {
  align-items: center;
  font-weight: 500;
  padding: 10px 0px 10px 10px;
  color: #e8fbdb;
}

.page-titre mat-icon {
  margin-right: 10px;
}

// SECTION

.section-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

// FORM

.form-container > * {
  margin-right: 10px;
}

.form-col {
  width: 350px;
  display: flex;
  flex-direction: column;
}

// BUTTON BAR

.btn-bar > button {
  margin-right: 10px;
}

/* RECHERCHE / FILTRE */

.search-container-title {
  margin-bottom: 6px;
}

.recherche-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recherche-btn-container button {
  margin-right: 10px;
  margin-bottom: 10px;
}

// CHECKBOX

.form-container .mat-checkbox {
  padding-bottom: 20px;
}

//************************************************
/*Classe pour les TABLES (genre <table>...) */
//************************************************

.table-liste {
  width: 100%;
}

.table-liste-clickable .mat-row:hover {
  background-color: $table-row-hover;
  cursor: pointer;
}

.table-liste-item-selected {
  background-color: $table-header-row;
}

.table-liste .mat-cell > span,
.table-liste .mat-mdc-cell > span {
  white-space: pre;
}
.table-liste .mat-cell,
.table-liste .mat-mdc-cell,
.table-liste .mat-header-cell,
.table-liste .mat-mdc-header-cell {
  padding-right: 10px;
}

// ALIGEMENT TABLE

.align-right {
  text-align: right;
}

.mat-cell.align-right,
.mat-mdc-cell.align-right {
  padding-right: 28px;
}

.align-center {
  text-align: center;
}

.mat-cell.align-center,
.mat-mdc-cell.align-center {
  padding-right: 28px;
}

.row-highlight {
  background-color: $table-row-selected;
}

.display-none {
  display: none;
}

//************************************************
/*Classe pour le PAGINATION  */
//************************************************
.pagination-count {
  font-size: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
  color: $text-primary-color;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
}

.pagination-container button {
  margin: 0 5px;
}

.pagination-pages {
  width: 150px;
  text-align: center;
}

.pagination-select {
  width: 100px;
}

//************************************************
/*Classe pour le INPUT  */
//************************************************
.input-label {
  margin-right: 5px;
}

.input-stroked {
  height: 32px;
  line-height: 30px;
  margin-right: 5px;
  background-color: $input-background-color;
  border: solid 1px $input-border-color;
  border-radius: 5px;
  color: $input-text-color;
  padding-left: 5px;
  padding-right: 5px;
}

// INPUT GENRAL

// SCROLL BAR

::-webkit-scrollbar {
  width: 17px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background: #aaa;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// KENDO

kendo-grid {
  background-color: $app-background-color !important;
  color: $input-text-color !important;

  //sort icon color
  .k-grid-header .k-icon {
    color: $input-text-color;
  }

  // Toolbar des bouton au dessus du grid
  kendo-grid-toolbar .kendo-export-excel-button {
    //Il faut imiter le style de Material pour le bouton export excel
    background-color: #ffc107 !important;
    border: transparent;
    color: black !important;
    padding: 5px 16px;
    line-height: 1;

    //Centrer les icones et le texts à l'intérieur du bouton export excel
    .k-button-text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .k-toolbar.k-grid-toolbar {
    background-color: $app-PageTitle-BackColor;
    border: 0px;
    .elements-count {
      color: $text-primary-color !important;
    }
  }

  // HEADER
  .k-grid-header-wrap,
  .k-grid-header {
    background-color: $table-header-row;
    color: $text-label-color;
  }
  //permet de réaligner l'header avec les colonnes du grid
  .k-grid-header {
    padding: 0px 15px 0px 0px !important;

    // aligner les bouton de menu de filtre vers le bas
    .k-cell-inner {
      align-items: flex-end;

      kendo-grid-filter-menu {
        padding-bottom: 4px !important;
      }
    }
  }
  // Permet de ne pas contraté le nom des colonnes à xxx... et de les collapse sur plusieurs lignes
  .k-column-title {
    white-space: normal !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }

  // Background deriere les lignes du grid
  .k-grid-content {
    background-color: $app-PageTitle-BackColor;
  }

  // Lignes du tableau de couleurs alternatives
  kendo-grid-list tr:nth-child(odd) td {
    background-color: #121c33;
  }
  kendo-grid-list tr:nth-child(even) td {
    background-color: $app-background-color;
  }

  // Ligne sélectée
  td.k-selected,
  tr.k-selected > td {
    background-color: $table-row-selected !important;
  }

  // LIGNES HOVER
  kendo-grid-list tr:hover td {
    background-color: $app-hover-element-color !important;
  }

  //PAGER
  kendo-pager.k-pager-wrap {
    color: $input-text-color;
    background-color: $table-header-row;
  }

  // Pager Bouttons
  .k-link.k-pager-nav,
  .k-icon {
    color: $input-text-color;
  }

  // Pager Bouttons hover
  .k-link.k-pager-nav:hover {
    color: $input-text-color;
    background-color: rgba($input-text-color, 0.1);
  }

  // Pager bouton sélectionné
  .k-link.k-selected.k-pager-nav {
    color: $input-text-color;
    background-color: rgba($input-text-color, 0.2);
  }

  // Kendo Inputs du header et du pager
  input.k-input-inner,
  input[kendotextbox],
  kendo-dropdownlist,
  kendo-dropdownlist span,
  kendo-numerictextbox,
  kendo-pager-numeric-buttons select,
  .k-button-solid-primary,
  .k-spinner-increase.k-button.k-icon-button,
  .k-spinner-decrease.k-button.k-icon-button,
  .k-button {
    background-color: $input-background-color !important;
    color: $input-text-color !important;
    border-color: $input-border-color !important;
  }
  kendo-dropdownlist {
    width: 90px !important; //on force a 90 car sinon le 1000 n'affiche pas
  }
  // Icone de filtre
  a.k-grid-filter-menu.k-grid-header-menu:hover {
    background-color: $app-hover-element-color !important;

    span.k-i-filter {
      color: rgba($input-text-color, 0.2);
    }
  }
  a.k-grid-filter-menu.k-grid-header-menu.k-active {
    //2023-03-08 : remettre la couleur par défaut pour plus de claireté
    //background-color: $table-row-selected;
  }

  // KENDO GRID SCROLLBAR

  ::-webkit-scrollbar {
    width: 14px;
    height: 15px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner {
    background: $table-header-row;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $text-label-color;
    border-radius: 20px;
    border: 3px solid $table-header-row;
    width: 8px;
    scrollbar-width: thin;
    scrollbar-base-color: $text-label-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $app-hover-element-color;
  }
}

kendo-grid-filter-menu-container {
  .k-button-solid-primary,
  .k-spinner-increase.k-button.k-icon-button,
  .k-spinner-decrease.k-button.k-icon-button,
  input.k-input-inner,
  input[kendotextbox],
  kendo-dropdownlist,
  kendo-dropdownlist span,
  kendo-numerictextbox,
  button {
    background-color: $input-background-color !important;
    color: $input-text-color !important;
    border-color: $input-border-color !important;
  }

  input[kendoradiobutton] {
    background-color: $input-text-color !important;
  }
  input[kendoradiobutton]:checked {
    background-color: $table-selected-row !important;
    border-color: $table-selected-row !important;
  }

  .k-radio:checked:focus,
  .k-radio.k-checked.k-focus {
    box-shadow: 0 0 0 2px rgb(14, 48, 80);
  }
}

//KENDO DROPDOWN LIST

.k-list {
  background-color: $input-background-color;

  ::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }

  ::-webkit-scrollbar-track {
    background: $table-header-row;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $text-label-color;
    border-radius: 20px;
    border: 3px solid $table-header-row;
    width: 8px;
    scrollbar-width: thin;
    scrollbar-base-color: $text-label-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $app-hover-element-color;
  }
}

.k-list-item {
  background-color: $input-background-color;
  color: $input-text-color;
  border-color: $text-label-color;
}

.k-list-item:hover {
  background-color: rgba($input-text-color, 0.1);
  color: $input-text-color;
  border-color: $text-label-color;
}

.k-list-item.k-selected,
.k-list-item.k-selected:hover {
  background-color: rgba($input-text-color, 0.2);
}

// Les dropdown list sont présent à l'intérieur des kendo popup
kendo-popup * {
  color: $input-text-color !important;
  background-color: $input-background-color !important;
}

.k-calendar-navigation::before,
.k-calendar-navigation::after,
.k-calendar-infinite .k-calendar-view::after {
  box-shadow: none !important;
}

kendo-popup *:hover {
  color: rgba($input-text-color, 0.5) !important;
  background-color: rgba($input-background-color, 0.5) !important;
}

kendo-popup .k-selected,
kendo-popup .k-selected * {
  background-color: $app-hover-element-color !important;
}

kendo-popup > *:first-child {
  background-color: $text-label-color;
}
