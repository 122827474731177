$site-toolbar-height: 65px;
$site-footer-height: 0em;

$mainpage-toolbar-height: 3em;

/* 

COULEURS

#0f172a   app background bleu noir - ancien jérôme

#374252   app layout border 

#334155   bouton normal gris 
#473FCE   bouton accent bleu 

#1E293B   card bg bleu 

#3B82F6   texte card bleu
#EF4444   texte card rouge
#F59E0B   texte card jaune
#22C55E   texte card vert
#3B172A   texte card bleu violet

#2C3344   sidemenu bg hover/selected
#C9CBCF   sidemenu texte normal
#676D79   sidemenu texte foncé
#C9CBCF   sidemenu texte selected
#818CD7   sidemenu texte titre

#94A3B8   bouton ("trois point" dans card et bouton texte sans markup)

*/

// APP

$app-background-color: #0f172a;
$app-PageTitle-BackColor: #040a19;
$app-PageTitle-FontColor: #c9cbcf;
$app-border-color: #374252;
$app-hover-element-color: #374262;
// CONTAINER
$container-background-color: #1e293b;

// BUTTON

$btn-icon-color: #94a3b8;

// TEXT

$text-primary-color: #3b82f6;
$text-form-color: #fff;
$text-label-color: #9ba3b3;

// INPUT

$input-background-color: #1c2738;
$input-border-color: #64748b;
$input-text-color: #fff;

// SIDENAV

$sidenav-text-color: #c9cbcf;
$sidenav-item-hover: #2c3344;
$sidenav-title-color: #3b82f6;
$sidenav-subtitle-color: #7d8492;

// TABLE
$table-row-hover: #fff2;
$table-row-selected: #fff2;
$table-header-row: #2c3344;
$table-header-text-color: #c9cbcf;
$table-selected-row: rgba(88, 99, 255, 0.45);

// TABS

$tab-text-color: #fff;
$tab-selected-color: #3b82f6;

// PALETTE

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$custom-primary-palette: (
  50: #e4f2ff,
  100: #bdddff,
  200: #93c8ff,
  300: #68b2ff,
  400: #4ba1ff,
  500: #3b82f6,
  600: #3b83f6,
  700: #3a70e2,
  800: #395ecf,
  900: #353eaf,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
